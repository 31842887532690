<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0">
                        {{
                            $t("message.new_m", {
                                m: $t("message.vacancy"),
                            })
                        }}
            </p>
        <div>
            <el-button type="success" size="medium" plain>{{ $t("message.save") }}</el-button>
            <el-button type="warning" size="medium" plain>{{ $t("message.close") }}</el-button>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
          <el-row :gutter="20">
            <el-col :span="12">
              <div class="app-form__group mb-4">
                <span class="input--label d-block mb-2">{{ $t("message.department_") }}</span>

                <el-select
                  size="medium"
                  class="w-100"
                  v-model="region1"
                  :placeholder="$t('message.department_')"
                  filterable
                >
                  <el-option label="Zone one" value="shanghai"></el-option>
                  <el-option label="Zone two" value="beijing"></el-option>
                </el-select>
              </div>

              <div class="app-form__group mb-4">
                <span class="input--label d-block mb-2">Сотрудник</span>

                <el-select
                  size="medium"
                  class="w-100"
                  v-model="region2"
                  placeholder="Сотрудник"
                  filterable
                >
                  <el-option label="Zone one" value="shanghai"></el-option>
                  <el-option label="Zone two" value="beijing"></el-option>
                </el-select>
              </div>
            </el-col>
            <!-- end col -->

            <el-col :span="12">
              <div class="app-form__group mb-4">
                <span class="input--label d-block mb-2">Подразделения</span>

                <el-select
                  size="medium"
                  class="w-100"
                  v-model="region3"
                  placeholder="Подразделения"
                  filterable
                >
                  <el-option label="Zone one" value="shanghai"></el-option>
                  <el-option label="Zone two" value="beijing"></el-option>
                </el-select>
              </div>

              <el-button type="success" size="medium" class="w-100 mt-3" round>
                Добавить чеклист
              </el-button>
            </el-col>
            <!-- end col -->

            <el-col :span="24">
              <ul class="worker-checkbox">
                <li><el-checkbox v-model="checked">Задача 1</el-checkbox></li>
                <li><el-checkbox v-model="checked">Задача 2</el-checkbox></li>
                <li><el-checkbox v-model="checked">Задача 3</el-checkbox></li>
                <li><el-checkbox v-model="checked">Задача 4</el-checkbox></li>
              </ul>

              <el-button type="success" size="medium" class="mt-5 w-100" round>
                Добавить задачу
              </el-button>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      region1: "",
      region2: "",
      region3: "",
      checked: "",
    };
  },
  components: {},
  mounted() {},
};
</script>
