<template>
  <div class="p-pegas">
    <div class="card-table">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="16">
            <div class="caz-blocks-sarcho-title">
              <div class="content-title d-flex align-center mr-2">
                {{$t('message.adaptation')}}
              </div>
              <div class="block-sarche">
                <div class="header__search my__header__search d-flex">
                  <el-input
                    placeholder="поиск"
                    prefix-icon="el-icon-search"
                    v-model="sarcheBlocks"
                    size="small"
                    round
                  ></el-input>
                  <el-select
                    v-model="value1"
                    placeholder="Ф.И.О"
                    size="small"
                    filterable
                  >
                    <el-option label="Zone one" value="shanghai"></el-option>
                    <el-option label="Zone two" value="beijing"></el-option>
                  </el-select>
                  <el-select
                    v-model="value2"
                    placeholder="Отдел"
                    size="small"
                    filterable
                  >
                    <el-option label="Zone one" value="shanghai"></el-option>
                    <el-option label="Zone two" value="beijing"></el-option>
                  </el-select>
                  <el-select
                    v-model="value3"
                    placeholder="Отдел"
                    size="small"
                    filterable
                  >
                    <el-option label="Zone one" value="shanghai"></el-option>
                    <el-option label="Zone two" value="beijing"></el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </el-col>

          <el-col :span="8" class="flex-style text-right">
            <el-button size="small" class="btu-color" @click="appModal = true">
              Создать
            </el-button>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="p-3 mb-5 mt-5">
        <el-row :gutter="20">
          <el-col :xs="8" :sm="12" :md="8" :lg="6" :xl="4">
            <el-card shadow="hover" class="mb-5">
              <div class="img-user-worker">
                <img src="../../../public/img/user2.jpg" alt="" />
              </div>
              <div class="name-user-worker mb-3">Abdullayeva Muxlisa</div>
              <ul class="worker-checkbox">
                <li><el-checkbox v-model="checked">Задача 1</el-checkbox></li>
                <li><el-checkbox v-model="checked">Задача 2</el-checkbox></li>
                <li><el-checkbox v-model="checked">Задача 3</el-checkbox></li>
                <li><el-checkbox v-model="checked">Задача 4</el-checkbox></li>
              </ul>

              <div class="user-progress">
                <el-progress
                  :text-inside="true"
                  :stroke-width="20"
                  :percentage="70"
                ></el-progress>
              </div>
            </el-card>
          </el-col>
          <!-- end col  -->

          <el-col :xs="8" :sm="12" :md="8" :lg="6" :xl="4">
            <el-card shadow="hover" class="mb-5">
              <div class="img-user-worker">
                <img src="../../../public/img/user2.jpg" alt="" />
              </div>
              <div class="name-user-worker mb-3">Abdullayeva Muxlisa</div>
              <ul class="worker-checkbox">
                <li><el-checkbox v-model="checked">Задача 1</el-checkbox></li>
                <li><el-checkbox v-model="checked">Задача 2</el-checkbox></li>
                <li><el-checkbox v-model="checked">Задача 3</el-checkbox></li>
                <li><el-checkbox v-model="checked">Задача 4</el-checkbox></li>
              </ul>

              <div class="user-progress">
                <el-progress
                  :text-inside="true"
                  :stroke-width="20"
                  :percentage="70"
                ></el-progress>
              </div>
            </el-card>
          </el-col>
          <!-- end col  -->

          <el-col :xs="8" :sm="12" :md="8" :lg="6" :xl="4">
            <el-card shadow="hover" class="mb-5">
              <div class="img-user-worker">
                <img src="../../../public/img/user2.jpg" alt="" />
              </div>
              <div class="name-user-worker mb-3">Abdullayeva Muxlisa</div>
              <ul class="worker-checkbox">
                <li><el-checkbox v-model="checked">Задача 1</el-checkbox></li>
                <li><el-checkbox v-model="checked">Задача 2</el-checkbox></li>
                <li><el-checkbox v-model="checked">Задача 3</el-checkbox></li>
                <li><el-checkbox v-model="checked">Задача 4</el-checkbox></li>
              </ul>

              <div class="user-progress">
                <el-progress
                  :text-inside="true"
                  :stroke-width="20"
                  :percentage="70"
                ></el-progress>
              </div>
            </el-card>
          </el-col>
          <!-- end col  -->

          <el-col :xs="8" :sm="12" :md="8" :lg="6" :xl="4">
            <el-card shadow="hover" class="mb-5">
              <div class="img-user-worker">
                <img src="../../../public/img/user2.jpg" alt="" />
              </div>
              <div class="name-user-worker mb-3">Abdullayeva Muxlisa</div>
              <ul class="worker-checkbox">
                <li><el-checkbox v-model="checked">Задача 1</el-checkbox></li>
                <li><el-checkbox v-model="checked">Задача 2</el-checkbox></li>
                <li><el-checkbox v-model="checked">Задача 3</el-checkbox></li>
                <li><el-checkbox v-model="checked">Задача 4</el-checkbox></li>
              </ul>

              <div class="user-progress">
                <el-progress
                  :text-inside="true"
                  :stroke-width="20"
                  :percentage="70"
                ></el-progress>
              </div>
            </el-card>
          </el-col>
          <!-- end col  -->

          <el-col :xs="8" :sm="12" :md="8" :lg="6" :xl="4">
            <el-card shadow="hover" class="mb-5">
              <div class="img-user-worker">
                <img src="../../../public/img/user2.jpg" alt="" />
              </div>
              <div class="name-user-worker mb-3">Abdullayeva Muxlisa</div>
              <ul class="worker-checkbox">
                <li><el-checkbox v-model="checked">Задача 1</el-checkbox></li>
                <li><el-checkbox v-model="checked">Задача 2</el-checkbox></li>
                <li><el-checkbox v-model="checked">Задача 3</el-checkbox></li>
                <li><el-checkbox v-model="checked">Задача 4</el-checkbox></li>
              </ul>

              <div class="user-progress">
                <el-progress
                  :text-inside="true"
                  :stroke-width="20"
                  :percentage="70"
                ></el-progress>
              </div>
            </el-card>
          </el-col>
          <!-- end col  -->

          <el-col :xs="8" :sm="12" :md="8" :lg="6" :xl="4">
            <el-card shadow="hover" class="mb-5">
              <div class="img-user-worker">
                <img src="../../../public/img/user2.jpg" alt="" />
              </div>
              <div class="name-user-worker mb-3">Abdullayeva Muxlisa</div>
              <ul class="worker-checkbox">
                <li><el-checkbox v-model="checked">Задача 1</el-checkbox></li>
                <li><el-checkbox v-model="checked">Задача 2</el-checkbox></li>
                <li><el-checkbox v-model="checked">Задача 3</el-checkbox></li>
                <li><el-checkbox v-model="checked">Задача 4</el-checkbox></li>
              </ul>

              <div class="user-progress">
                <el-progress
                  :text-inside="true"
                  :stroke-width="20"
                  :percentage="70"
                ></el-progress>
              </div>
            </el-card>
          </el-col>
          <!-- end col  -->

          <el-col :xs="8" :sm="12" :md="8" :lg="6" :xl="4">
            <el-card shadow="hover" class="mb-5">
              <div class="img-user-worker">
                <img src="../../../public/img/user2.jpg" alt="" />
              </div>
              <div class="name-user-worker mb-3">Abdullayeva Muxlisa</div>
              <ul class="worker-checkbox">
                <li><el-checkbox v-model="checked">Задача 1</el-checkbox></li>
                <li><el-checkbox v-model="checked">Задача 2</el-checkbox></li>
                <li><el-checkbox v-model="checked">Задача 3</el-checkbox></li>
                <li><el-checkbox v-model="checked">Задача 4</el-checkbox></li>
              </ul>

              <div class="user-progress">
                <el-progress
                  :text-inside="true"
                  :stroke-width="20"
                  :percentage="70"
                ></el-progress>
              </div>
            </el-card>
          </el-col>
          <!-- end col  -->

          <el-col :xs="8" :sm="12" :md="8" :lg="6" :xl="4">
            <el-card shadow="hover" class="mb-5">
              <div class="img-user-worker">
                <img src="../../../public/img/user2.jpg" alt="" />
              </div>
              <div class="name-user-worker mb-3">Abdullayeva Muxlisa</div>
              <ul class="worker-checkbox">
                <li><el-checkbox v-model="checked">Задача 1</el-checkbox></li>
                <li><el-checkbox v-model="checked">Задача 2</el-checkbox></li>
                <li><el-checkbox v-model="checked">Задача 3</el-checkbox></li>
                <li><el-checkbox v-model="checked">Задача 4</el-checkbox></li>
              </ul>

              <div class="user-progress">
                <el-progress
                  :text-inside="true"
                  :stroke-width="20"
                  :percentage="70"
                ></el-progress>
              </div>
            </el-card>
          </el-col>
          <!-- end col  -->

          <el-col :xs="8" :sm="12" :md="8" :lg="6" :xl="4">
            <el-card shadow="hover" class="mb-5">
              <div class="img-user-worker">
                <img src="../../../public/img/user2.jpg" alt="" />
              </div>
              <div class="name-user-worker mb-3">Abdullayeva Muxlisa</div>
              <ul class="worker-checkbox">
                <li><el-checkbox v-model="checked">Задача 1</el-checkbox></li>
                <li><el-checkbox v-model="checked">Задача 2</el-checkbox></li>
                <li><el-checkbox v-model="checked">Задача 3</el-checkbox></li>
                <li><el-checkbox v-model="checked">Задача 4</el-checkbox></li>
              </ul>

              <div class="user-progress">
                <el-progress
                  :text-inside="true"
                  :stroke-width="20"
                  :percentage="70"
                ></el-progress>
              </div>
            </el-card>
          </el-col>
          <!-- end col  -->

          <el-col :xs="8" :sm="12" :md="8" :lg="6" :xl="4">
            <el-card shadow="hover" class="mb-5">
              <div class="img-user-worker">
                <img src="../../../public/img/user2.jpg" alt="" />
              </div>
              <div class="name-user-worker mb-3">Abdullayeva Muxlisa</div>
              <ul class="worker-checkbox">
                <li><el-checkbox v-model="checked">Задача 1</el-checkbox></li>
                <li><el-checkbox v-model="checked">Задача 2</el-checkbox></li>
                <li><el-checkbox v-model="checked">Задача 3</el-checkbox></li>
                <li><el-checkbox v-model="checked">Задача 4</el-checkbox></li>
              </ul>

              <div class="user-progress">
                <el-progress
                  :text-inside="true"
                  :stroke-width="20"
                  :percentage="70"
                ></el-progress>
              </div>
            </el-card>
          </el-col>
          <!-- end col  -->

          <el-col :xs="8" :sm="12" :md="8" :lg="6" :xl="4">
            <el-card shadow="hover" class="mb-5">
              <div class="img-user-worker">
                <img src="../../../public/img/user2.jpg" alt="" />
              </div>
              <div class="name-user-worker mb-3">Abdullayeva Muxlisa</div>
              <ul class="worker-checkbox">
                <li><el-checkbox v-model="checked">Задача 1</el-checkbox></li>
                <li><el-checkbox v-model="checked">Задача 2</el-checkbox></li>
                <li><el-checkbox v-model="checked">Задача 3</el-checkbox></li>
                <li><el-checkbox v-model="checked">Задача 4</el-checkbox></li>
              </ul>

              <div class="user-progress">
                <el-progress
                  :text-inside="true"
                  :stroke-width="20"
                  :percentage="70"
                ></el-progress>
              </div>
            </el-card>
          </el-col>
          <!-- end col  -->

          <el-col :xs="8" :sm="12" :md="8" :lg="6" :xl="4">
            <el-card shadow="hover" class="mb-5">
              <div class="img-user-worker">
                <img src="../../../public/img/user2.jpg" alt="" />
              </div>
              <div class="name-user-worker mb-3">Abdullayeva Muxlisa</div>
              <ul class="worker-checkbox">
                <li><el-checkbox v-model="checked">Задача 1</el-checkbox></li>
                <li><el-checkbox v-model="checked">Задача 2</el-checkbox></li>
                <li><el-checkbox v-model="checked">Задача 3</el-checkbox></li>
                <li><el-checkbox v-model="checked">Задача 4</el-checkbox></li>
              </ul>

              <div class="user-progress">
                <el-progress
                  :text-inside="true"
                  :stroke-width="20"
                  :percentage="70"
                ></el-progress>
              </div>
            </el-card>
          </el-col>
          <!-- end col  -->
        </el-row>
        <!-- end row  -->
      </div>
    </div>
    <div class="app-modal app-modal__full modal-widt-70">
      <el-dialog :visible.sync="appModal" :fullscreen="true" width="">
        <div
          @click="appModal = false"
          class="
            close-modal-stick
            d-flex
            align-center
            p-fixed
            text-white
            p-2
            font-bold
            pointer
            transition
          "
        >
          <div class="close-modal-icon text-center rounded-circle transition">
            <i class="el-icon-close"></i>
          </div>
          <span class="close-modal-text text-uppercase ml-2"></span>
        </div>
        <CrmCreate />
      </el-dialog>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import CrmCreate from "./components/crm-create";
export default {
  name: "adaptation",
  components: {
    CrmCreate,
  },

  data() {
    return {
      appModal: false,
      form: {},
      sarcheBlocks: "",
      value2: "",
      value3: "",
      value4: "",
      value1: "",
      checked: "",
    };
  },
};
</script>

